
import { Options, Vue } from 'vue-class-component';
import { AppUserClient } from '@/services/Services';
import QrScan from '@/components/QrScan.vue';
import WrongScanModal from '../modals/wrongScanModal.vue';
import SuccessScanModal from '../modals/successScanModal.vue';
import ErrorModal from '../modals/errorModal.vue';
import store from '@/store';

@Options({
    components: {
        QrScan
    }
})
export default class ScanForBracelet extends Vue {

    emailForCheck: string = "";
    userIdentifier: string = "";
    loaded: boolean = false;
    
    created() {
        store.state.showSpinner++;
        setTimeout(() => {
            store.state.showSpinner--;
            this.loaded = true;
        }, 1500);
    }

    checkByEmail() {
        AppUserClient.getUserIdentifierBy(this.emailForCheck)
        .then(x => {
            if(!x) {
                this.$opModal.show(ErrorModal, {
                    title: "Utente non trovato!",
                    text: "L'utente non fa parte della community o non ha ancora fatto accesso alla webapp dell'evento",
                    callback: () => {
                        window.location.reload();
                    }
                })
            }
            else {
                this.userIdentifier = x;
                this.checkIfCanHaveWrist();
            }
        })
    }

    callback(data: string) {
        if(!data.trim())
            return;

        this.userIdentifier = data;
        this.checkIfCanHaveWrist();
    }

    checkIfCanHaveWrist() {
        AppUserClient.checkIfIsOwner(this.userIdentifier)
        .then(x => {
            if(!x) {
                this.$opModal.show(ErrorModal, {
                    title: "Gadget per soli membri OWNER!",
                    text: "L'utente non risulta essere un membro OWNER della nostra community",
                    callback: () => {
                        window.location.reload();
                    }
                })
            }
            else {
                AppUserClient.setWristTaken(this.userIdentifier)
                .then(x => {
                    if(!x) {
                        this.$opModal.show(SuccessScanModal, {
                            homeCallback: () => {
                                this.$opModal.closeLast();
                                this.$router.replace("/dashboard");
                            },
                            callback: () => {
                                window.location.reload();
                            }
                        })
                    }
                    else {
                        this.$opModal.show(WrongScanModal, {
                            rescueDate: x,
                            homeCallback: () => {
                                this.$opModal.closeLast();
                                this.$router.replace("/dashboard");
                            },
                            callback: () => {
                                window.location.reload();
                            }
                        })
                    }
                })
            }
        })
    }

}
